<template>
  <v-card min-height="400" flat class="xw-full">
    <!-- Change/Update Card -->
    <EachSetting
      v-if="subscription && subscription.status === 'BASIC MONTHLY'"
      title="Update card information"
      :with-expand="false"
      :subtitle="`Update or change the card used for your ${appName} subscription.`"
    >
      <template v-slot:allright>
        <v-btn
          color="primary"
          depressed
          class="text-none px-8 xmax-w-[350px] xmx-auto"
          @click="$router.push({ name: 'app-update-information' })"
        >
          Update Card
        </v-btn>
      </template>
    </EachSetting>
    <!-- <pre>{{ subscription }}</pre> -->
    <!-- Set new users as paying -->
    <EachSetting
      title="Set new users as paying"
      subtitle="You can choose to add new users either automatically as paying users (with clocking enabled) or manually."
    >
      <template v-slot:left>
        <strong>Turn Off</strong>
        <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
          To manually add new users as paying users (with clocking allowed),
          select this option.
        </div>
        <strong>Turn On</strong>
        <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
          Enable this option to automatically add new users as paying users
          (with clocking allowed).
        </div>
      </template>
      <template v-slot:allright>
        <div
          class="xcol-span-1 xflex xflex-row xjustify-center xgap-x-4 xitems-center xp-2"
        >
          <span class="xfont-semibold">Off</span>
          <v-switch
            :disabled="!$can.and('update_team_preferences')"
            :true-value="1"
            :false-value="0"
            color="#19CB40"
            v-model="auto_add_users"
            @change="handleChange"
            inset
          ></v-switch>
          <span class="xfont-semibold">On</span>
        </div>
      </template>
    </EachSetting>
    <!-- <v-divider /> -->
    <v-card-title
      class="xflex xflex-row xjustify-between xgap-2 xitems-center xpx-0"
    >
      <div
        :class="[
          tabletDown ? 'xw-full' : '',
          mobile ? 'xflex-col' : 'xflex-row',
        ]"
        class="xflex xjustify-end xgap-2 xitems-center"
      >
        <!-- <v-text-field
          class="md:xmax-w-[300px] xmax-w-full xw-full"
          prepend-inner-icon="mdi-magnify"
          dense
          outlined
          clearable
          hide-details="auto"
          v-model="search"
          placeholder="Search..."
          append-icon="mdi-chevron-down"
        >
          Default Slot
        </v-text-field> -->

        <v-select
          class="xw-full md:xmax-w-[200px]"
          v-model="status"
          outlined
          dense
          hide-details="auto"
          item-text="text"
          item-value="value"
          :items="[
            { text: 'All', value: '' },
            { text: 'Active', value: 'active' },
            { text: 'Inactive', value: 'inactive' },
            { text: 'Paying', value: 'paying' },
          ]"
        >
        </v-select>
      </div>
      <div
        :class="[
          tabletDown ? 'xw-full' : '',
          mobile ? 'xflex-col' : 'xflex-row',
        ]"
        class="xflex xjustify-end xgap-2 xitems-center"
      >
        <v-btn
          :loading="saving"
          color="primary"
          :block="smDown"
          @click="saveChanges"
          :disabled="!payings.length || saving"
          class="px-5 text-none"
          depressed
        >
          <v-icon left v-if="mdUp">mdi-content-save</v-icon>
          Save Changes
        </v-btn>
        <SyncToSlack
          :block="smDown"
          v-if="user"
          :team-id="user.team_id"
        ></SyncToSlack>
      </div>
    </v-card-title>

    <app-table :loading="fetching" :headings="headers" :items="allusers">
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'app-user-record-monthly',
            params: {
              id: item.id,
              year_month: yearMonth,
            },
          }"
        >
          <avatar size="35" with-name :user="item"></avatar>
        </router-link>
      </template>
      <template v-slot:title="{ item }">
        {{ item.job_title }}
      </template>
      <template v-slot:role="{ item }">
        {{ item.role ? item.role.normalize_name : "Slack User" }}
      </template>
      <template v-slot:deleted_at="{ item }">
        <icon-tooltip
          v-if="item.deleted_at"
          color="error"
          class="xmx-auto"
          tip="User is inactive"
          icon="mdi-account-remove"
        ></icon-tooltip>
        <icon-tooltip
          v-else
          class="xmx-auto"
          color="success"
          tip="User is active"
          icon="mdi-account-check"
        ></icon-tooltip>
      </template>
      <template v-slot:is_paying="{ item }">
        <v-switch
          inset
          :disabled="!!item.deleted_at"
          class="xmy-0"
          color="#19CB40"
          v-model="payings"
          :value="item.id"
          on-icon="mdi-clipboard-check"
          off-icon="mdi-clipboard-outline"
          :true-value="item.id"
          :false-value="null"
          hide-details="auto"
        ></v-switch>
      </template>
    </app-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachSetting from "../../Settings/components/EachSetting.vue";
export default {
  components: { EachSetting },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", [
      "workspace",
      "allusers",
      "subscription",
      "syncing",
      "fetching",
    ]),
    ...mapGetters("workspaceconfigs", ["getByKey"]),
    currentPayingCount() {
      return this.allusers.filter((i) => i.is_paying).length;
    },
    payload() {
      return {
        ids: this.payings,
      };
    },
    auto_add_users: {
      get() {
        return this.getByKey("auto_add_users", 0);
      },
      set(val) {
        this.set_config({ key: "auto_add_users", value: val });
      },
    },
    headers() {
      return [
        { text: "Name", value: "name", sortable: true, align: "left" },
        {
          text: "Title",
          value: "title",
          sortable: true,
          align: "left",
          width: 200,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
          align: "left",
        },
        { text: "Main Role", value: "role", sortable: false, align: "left" },
        {
          text: "Active",
          value: "deleted_at",
          sortable: true,
          width: 100,
          align: "left",
        },
        {
          text:
            this.subscription && this.subscription.status === "EXEMPTED"
              ? "Allow Clocking"
              : "Add To Paying",
          value: "is_paying",
          sortable: false,
          width: 120,
          tdClass: "xtext-center",
        },
      ];
    },
    yearMonth() {
      return moment().format("YYYY-MM");
    },
  },
  watch: {
    allusers: {
      handler: function (val) {
        this.payings = !val
          ? []
          : _.cloneDeep(val)
              .filter((i) => i.is_paying)
              .map((j) => j.id);
      },
      immediate: true,
      deep: true,
    },
    status() {
      this.fetchUsers();
    },
    search() {
      this.debouncedFetchUsers();
    },
  },
  data() {
    return {
      saving: false,
      payings: [],
      showInactive: false,
      status: "active",
      search: "",
    };
  },
  created() {
    this.fetchUsers();
    this.fetchWorkspaceConfigs(this.user.team_id);
  },
  methods: {
    onSelect(val) {
      this.items = [val];
    },
    ...mapMutations(["set_step1"]),
    ...mapMutations("workspaceconfigs", ["set_config"]),
    ...mapActions("workspace", [
      "fetchWorkspaceAllUsers",
      "fetchWorkspaceStatistics",
    ]),
    ...mapActions("workspaceconfigs", [
      "updateWorkspaceConfig",
      "fetchWorkspaceConfigs",
    ]),
    handleChange(val) {
      this.updateWorkspaceConfig({
        id: this.user.team_id,
        payload: { key: "auto_add_users", value: val },
        cb: () => {
          this.appToast("Success! Configuration updated.", "success");
        },
      });
    },
    fetchUsers() {
      this.fetchWorkspaceAllUsers({
        id: this.user.team_id,
        status: this.status,
        search: this.search,
      });
    },
    debouncedFetchUsers: _.debounce(function () {
      this.fetchUsers();
    }, 300),
    saveChanges() {
      this.saving = true;
      this.$axios
        .post(`api/workspaces/users/payings`, this.payload)
        .then(({ data }) => {
          this.appToast("Success! Your changes have been saved.", "success");
          this.fetchUsers();
          this.fetchWorkspaceStatistics(this.workspace.id);
          this.set_step1(false);
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
